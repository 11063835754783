import React from 'react';
import TapListHeader from './TapListHeader';
import { TapListItem } from './TapListItem';
import { Tap } from '../../types/tap';

interface TapListProps {
  activeTaps: Tap[];
  onDeckTaps: Tap[];
  inactiveTaps: Tap[];
  onEdit: (tap: Tap) => void;
}

export const TapList: React.FC<TapListProps> = ({ activeTaps, onDeckTaps, inactiveTaps, onEdit }) => {
  return (
    <div className="grid grid-cols-[1fr_72px] sm:grid-cols-[72px_1fr_72px] md:grid-cols-[88px_1fr_auto_88px]">
      {activeTaps.length > 0 && (
        <>
          <TapListHeader
            title="Taps.ActiveTapsHeader"
            type="active"
            hasActiveTaps={activeTaps.length > 0}
            hasOnDeckTaps={onDeckTaps.length > 0}
          />
          {activeTaps.map((tap) => (
            <TapListItem
              key={tap._id}
              tap={tap}
              onClick={(e: React.MouseEvent | React.TouchEvent) => {
                e.stopPropagation();
                onEdit(tap);
              }}
              onUpdate={() => {}}
            />
          ))}
        </>
      )}

      {onDeckTaps.length > 0 && (
        <>
          <TapListHeader
            title="Taps.OnDeckTapsHeader"
            type="onDeck"
            hasActiveTaps={activeTaps.length > 0}
            hasOnDeckTaps={onDeckTaps.length > 0}
          />
          {onDeckTaps.map((tap) => (
            <TapListItem
              key={tap._id}
              tap={tap}
              onClick={(e: React.MouseEvent | React.TouchEvent) => {
                e.stopPropagation();
                onEdit(tap);
              }}
              onUpdate={() => {}}
            />
          ))}
        </>
      )}

      {inactiveTaps.length > 0 && (
        <>
          <TapListHeader
            title="Taps.InactiveTapsHeader"
            type="inactive"
            hasActiveTaps={activeTaps.length > 0}
            hasOnDeckTaps={onDeckTaps.length > 0}
          />
          {inactiveTaps.map((tap) => (
            <TapListItem
              key={tap._id}
              tap={tap}
              onClick={(e: React.MouseEvent | React.TouchEvent) => {
                e.stopPropagation();
                onEdit(tap);
              }}
              onUpdate={() => {}}
            />
          ))}
        </>
      )}
    </div>
  );
};
